export const columns = [
	{
		prop: 'roleNo',
		label: '角色编号',
	},
	{
		prop: 'roleName',
		label: '角色名称',
	},
	{
		prop: 'rolePermissions',
		label: '数据权限',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	},
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '角色名称',
			attr: 'roleName',
			type: 'search',
			placeholder: '请输入角色名称',
			clearable: true,
		},
	],
}

export const rules = {
	addressDesc: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
	roleName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	rolePermissions: [{ required: true, message: '请选择数据权限', trigger: 'blur' }],
	contactsPhone: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
	contactsPerson: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
	companyIds: [{ required: true, message: '请选择合作公司', trigger: 'blur' }],
}
