<template>
	<el-dialog
		v-model="isShowDialog"
		class="role-dialog-container"
		:width="formType === 'set-auth' ? 850 : 600"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="角色名称" prop="roleName">
							<el-input
								v-model="formData.roleName"
								placeholder="请输入角色名称"
								maxlength="20"
								show-word-limit
								:disabled="formType !== 'create'"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="数据范围" prop="rolePermissions">
							<el-radio-group
								v-model="formData.rolePermissions"
								:disabled="formType === 'set-auth'"
							>
								<el-radio
									v-for="(item, index) in rolePermissionsOptions"
									:key="index"
									:label="item.code"
								>
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType === 'set-auth'">
					<el-col :span="21">
						<el-form-item label="" prop="menus">
							<el-radio-group
								v-model="activeApp"
								size="default"
								class="radio-box"
								@change="onChangeAppTab"
							>
								<el-radio-button
									v-for="appItem in appOptions"
									:key="appItem.id"
									:label="appItem.id"
								>
									{{ appItem.name }}
								</el-radio-button>
							</el-radio-group>
							<div class="checkbox-box">
								<el-checkbox-group v-model="menuSelected">
									<div v-for="(menu, mIndex) in menuList" :key="menu.nid" class="checkbox-item">
										<div class="item-parent">
											<el-checkbox :label="menu.nid">{{ menu.name }}</el-checkbox>
										</div>
										<div class="item-children" v-if="menu.children.length > 0">
											<div
												v-for="(oneChildren, oIndex) in menu.children"
												class="children-item"
												:key="oIndex"
												:class="oneChildren.children.length > 0 ? '' : 'no-children-two'"
											>
												<div class="children-one">
													<el-row :gutter="10">
														<el-col :span="6">
															<el-checkbox :label="oneChildren.nid">
																{{ oneChildren.name }}
															</el-checkbox>
														</el-col>
													</el-row>
												</div>
												<div class="children-two" v-if="oneChildren.children.length > 0">
													<el-row :gutter="10">
														<el-col
															v-for="(twoChildren, tIndex) in oneChildren.children"
															:key="twoChildren.nid"
															:span="6"
														>
															<el-checkbox :label="twoChildren.nid">
																{{ twoChildren.name }}
															</el-checkbox>
														</el-col>
													</el-row>
												</div>
											</div>
										</div>
									</div>
								</el-checkbox-group>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button
					v-if="formType === 'set-auth'"
					:loading="isLoading"
					type="primary"
					:disabled="isDisabled"
					@click="onSubmitAuth"
				>
					保存菜单
				</el-button>
				<el-button
					v-else
					:loading="isLoading"
					type="primary"
					:disabled="isDisabled"
					@click="onSubmit"
				>
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')

const formInitValue = {
	roleName: '',
	rolePermissions: '',
}
const activeApp = ref('')

const formData = reactive({ ...formInitValue })
const formRule = rules
const appOptions = ref([])
const menuList = ref([])
const menuSelected = ref([])

const statusOptions = [
	{ name: '启用', code: 1 },
	{ name: '禁用', code: 0 },
]

const rolePermissionsOptions = [
	{ name: '个人', code: 10 },
	{ name: '公司', code: 20 },
]

const formType = ref('create')
let waitUpdateId = ''

function buildMenuTree(originTree) {
	return originTree.map(menu => {
		menu.nid = JSON.stringify({
			appId: menu.appId,
			menuId: menu.id,
		})
		if (menu.children) {
			menu.children = buildMenuTree(menu.children)
		}
		return menu
	})
}

function onChangeAppTab(e) {
	console.log('当前选择应用变更 ==>', e)
	Request({
		url: `suf4-system-service/menu/tree/${e}`,
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				menuList.value = buildMenuTree(res.data)
				console.log('角色树获取成功 ==>', menuList.value)
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function initEnums() {
	Request({
		url: 'suf4-system-service/app/list',
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200 && res.data.length > 0) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				appOptions.value = res.data
				activeApp.value = res.data[0].id
				onChangeAppTab(res.data[0].id)
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function setAuth(item) {
	formType.value = 'set-auth'
	dialogTitle.value = '编辑角色权限'
	waitUpdateId = item.id
	Object.assign(formData, item)
	getRoleMenuList(item.id)
	initEnums()
	isShowDialog.value = true
}

function getRoleMenuList(roleId) {
	Request({
		url: `suf4-user-service/roleMenuRel/listByRoleId/${roleId}`,
		method: 'POST',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('当前权限获取成功 ==>', res.data)
				menuSelected.value = res.data.map(menu => {
					return JSON.stringify({
						appId: menu.appId,
						menuId: menu.menuId,
					})
				})
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType.value = 'create'
	dialogTitle.value = '新增角色'
	isShowDialog.value = true
}

function update(item) {
	formType.value = 'update'
	dialogTitle.value = '编辑角色'
	waitUpdateId = item.id
	Object.assign(formData, item)
	isShowDialog.value = true
}

function deleteRole(item) {
	$messageBox
		.confirm('确定将删除该角色？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {
			return Request({
				url: `suf4-user-service/role/removeById/${item.id}`,
				method: 'GET',
			})
		})
		.then(res => {
			if (res.code === 200) {
				$message.success('删除角色成功!')
				emit('refresh')
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			if (!error.message) return
			$message.error(error.message)
		})
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		let params = {}
		if (formType.value === 'create') {
			requestUrl = `suf4-user-service/role/create`
			delete params.id
			params.roleName = formData.roleName
			params.rolePermissions = formData.rolePermissions
		} else {
			requestUrl = `suf4-user-service/role/update`
			params = formData
			params.id = waitUpdateId
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType.value === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

async function onSubmitAuth() {
	try {
		const updateRes = await Request({
			url: 'suf4-user-service/roleMenuRel/submit',
			method: 'POST',
			data: {
				roleId: waitUpdateId,
				menuList: menuSelected.value.map(menuNId => {
					const nId = JSON.parse(menuNId)
					return {
						menuId: nId.menuId,
						appId: nId.appId,
					}
				}),
			},
		})
		if (updateRes.code === 200) {
			$message.success(`权限配置成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '权限配置失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	menuSelected.value = []
	Object.assign(formData, formInitValue)
}

defineExpose({ create, update, setAuth, deleteRole })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
